<template>
    <div class="authLayout row align-items-end m-0 align-items-md-center ">
      <div class="authLayout__content col-12 p-0 col-md-6" :class="{'-login order-0': isLogin, '-register order-md-1': isRegister }">
        <div class="row m-0">
          <div v-if="isLogin" class="col-12 my-5 authLayout__contentLogin d-flex flex-column justify-content-center align-items-center text-center">
            <h1 key="random3" class="text-white enter-1 mb-5 order-1 order-md-0">
                WebOk
            </h1>
            <h3 key="random2" class="text-white enter-2 order-2 order-md-1">
              <strong>Welcome to WebOk.</strong>
            </h3>
            <div key="random" class="text-white enter-3 order-3 order-md-2">
              <p class="text-white">
                Description web
              </p>
            </div>

            <img class="img-fluid img-landing-right enter-4 order-0 order-md-4" src="../../public/img/webOk.png" alt="webOk">
          </div>
          <div v-if="isRegister" class="col-12 my-5 my-md-0 authLayout__contentRegister">
            <img class="img-fluid  mb-5 enter-1" src="../../public/img/webOk.png" alt="webOk">
          </div>
        </div>
      </div><!-- authLayout__content -->
      <div class="authLayout__form d-flex justify-content-end enter-5 p-0 col-12 col-md-6">
        <router-view />
      </div><!-- authLayout__form -->
      <div class="mt-5">
      </div>
    </div><!-- authLayout -->
</template>

<script>

import { useRoute } from 'vue-router';


export default {
  name: "AuthLayout",
  components: {
  },
  data (){
    return{
      currentRoute: ''
    }
  },
  mounted: function () {

    setTimeout(() => {
        this.currentRoute = this.currentRoutePath;
    }, 200);
    
  },
  watch:{
    $route (to, from){
      this.currentRoute = this.$route.path;
    }
  },
  computed: {
    currentRoutePath() {
        return this.$route.path;
    },
    isLogin(){
      return (this.currentRoute == '/' || this.currentRoute == 'login');
    },
    isRegister(){
      return this.currentRoute == 'registrarse';
    }
  }
};
</script>

<style scoped lang="scss">

.img-landing-right{
  height: 300px !important;
}

.authLayout {
  overflow-x: hidden;
  min-height: 97vh;
}

@media (max-width: 768px) {
  .authLayout {
    background: linear-gradient(to bottom right, #badff1, #113748);
  }
}

// .authLayout__content img {
//   width: 100%;
//   max-width: 500px;
// }

@media (max-width: 768px) {
  .authLayout__content img {
    max-width: 250px;
  }
}

.authLayout__content > * {
  position: relative;
  z-index: 6;
}

.authLayout__content::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 300vw;
  transform: translate(35%, 0);
  background-image: linear-gradient(to bottom right, #badff1, #113748);
  transition: 1s ease-in-out;
  z-index: 6;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  border-bottom-right-radius: max(50vw, 50vh);
  border-top-left-radius: max(50vw, 50vh);
}

@media (max-width: 768px) {
  .authLayout__content::before {
    display: none;
  }
}

.authLayout__content h1 {
  font-size: 3em;
  font-weight: bold;
}

@media (max-width: 768px) {
  .authLayout__content h1 {
    font-size: 2.3em;
  }
}

.authLayout__content h3 {
  font-size: 2em;
  max-width: 600px;
}

@media (max-width: 768px) {
  .authLayout__content h3 {
    font-size: 1.4em;
  }
}

.authLayout__content .Register {
  text-align: center;
  position: relative;
  top: 35%;
}

.authLayout__content .Login {
  text-align: center;
}

@media (min-width: 768px) {
  .authLayout__content .Login {
    margin-top: 150px;
  }
}

.authLayout__content .Login img {
  @media (min-width: 1250px) {
    margin-top: 150px;
  }

  @media (min-width: 768px) {
    margin-top: 60px;
  }
}

.authLayout__content.-login::before {
  transform: translate(0, 0);
  right: 50%;
}

.authLayout__content.-register::before {
  transform: translate(100%, 0);
  right: 50%;
}

.enter-1,
.enter-2,
.enter-3,
.enter-4,
.enter-5 {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 600ms;
  animation-timing-function: ease;
}

.enter-1 {
  animation-delay: 0.6s;
  animation-name: enterLeft;
}

.enter-2 {
  animation-delay: 0.7s;
  animation-name: enterLeft;
}

.enter-3 {
  animation-delay: 0.8s;
  animation-name: enterLeft;
}

.enter-4 {
  animation-delay: 0.9s;
  animation-name: enterLeft;
}

.enter-5 {
  animation-delay: 1s;
  animation-name: enterScale;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
}

@keyframes enterLeft {
  0% {
    opacity: 0;
    transform: translateX(-230px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes enterScale {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

</style>
