import { createWebHistory, createRouter } from "vue-router";
import AuthLayout from "../layouts/AuthLayout.vue";
import MainLayout from "../layouts/MainLayout.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: AuthLayout,
    children: [
      {
        path: '/',
        name: 'login',
        component: () => import('../views/LandingAuth.vue'),
      },
    ],
  },
  {
    path: "/home",
    name: "Home",
    component: MainLayout,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home.vue'),
      },
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    component: MainLayout,
    children: [
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/Profile.vue'),
      },
    ],
  },
  {
    path: "/billing",
    name: "billing",
    component: MainLayout,
    children: [
      {
        path: '/billing',
        name: 'billing',
        component: () => import('../views/Billing.vue'),
      },
    ],
  },
  {
    path: "/table",
    name: "table",
    component: MainLayout,
    children: [
      {
        path: '/table',
        name: 'table',
        component: () => import('../views/Table.vue'),
      },
    ],
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;