<template>
<nav class="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
    <button @click="navbarMenu" class="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0" id="sidebarToggle"><i class="fas fa-bars"></i></button>
    <a class="navbar-brand pe-3 ps-4 ps-lg-2">
        <img class="img-navbar" style="height:80px !important" src="../../../public/img/webOk.png">
    </a>
    <ul class="navbar-nav align-items-center ms-auto">
        <li class="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
            <a class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="img-account-profile rounded-circle mb-2" src="../../../public/img/profile.png" alt="" /></a>
            <div class="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
                <h6 class="dropdown-header d-flex align-items-center">
                    <img class="img-account-profile rounded-circle mb-2" src="../../../public/img/profile.png" alt="" />
                    <div class="dropdown-user-details">
                        <div class="dropdown-user-details-name">Name webOk</div>
                        <div class="dropdown-user-details-email">example@webOk.com</div>
                    </div>
                </h6>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item cursor-pointer">
                    <div class="dropdown-item-icon cursor-pointer"><i data-feather="log-out"></i></div>
                    Exit
                </a>
            </div>
        </li>
    </ul>
</nav>
</template>

    
<style>
.img-navbar {
    cursor: pointer;
    height: 65px !important;
    margin: 1px;
    padding: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 10px;
    margin-bottom: 10px;
}

.user-profile {
    font-size: 35px;
    color: #113748;
}

.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-img {
    height: 2.5rem;
    width: 2.5rem;
    margin: auto;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 20px;
}

.img-account-profile {
    height: 3rem;
}
</style>

    
<script>
import router from '@/router/index.js' // Vue router instance

export default {
    components: {},
    props: {},
    mounted: function () {},
    methods: {
        navbarMenu() {
            let responsive = document.body.classList.contains('sidenav-toggled')
            let body = document.body;
            if (!responsive) {
                body.classList.add("sidenav-toggled");
            } else {
                body.classList.remove("sidenav-toggled");
            }
        },
    },
    computed: {}
}
</script>
