<template>
    <MainNavbar />
    <div id="layoutSidenav">
        <Sidebar />
        <div @click="hideNav" id="layoutSidenav_content">
            <main>
                <router-view :key="$route.fullPath" />
            </main>
            <Footer />
        </div><!-- layoutSidenav_content -->
    </div><!-- layoutSidenav -->
</template>

<script>
import MainNavbar from '../components/layout/Navbar.vue'
import Sidebar from '../components/layout/Sidebar.vue'

export default {
    name: 'BaseLayout',
    components: {
        MainNavbar,
        Sidebar
    },
    mounted() {},
    methods: {
        hideNav: function () {
            document.body.classList.remove("sidenav-toggled");
        }
    },
    computed: {},
}
</script>

<style lang="scss" scoped>

</style>
