<template>
<div id="layoutSidenav_nav">
    <nav class="sidenav shadow-right sidenav-light">
        <div class="sidenav-menu">
            <div class="nav accordion" id="accordionSidenav">
                <div class="sidenav-menu-heading">Pages</div>
                <a class="nav-link collapsed title cursor-pointer" @click="redirect('home')" id="menu_home" :class="{'itemOn': this.$route.path == '/home'}" data-bs-toggle="collapse" data-bs-target="#collapseDashboards" aria-expanded="false" aria-controls="collapseDashboards">
                    <div class="nav-link-icon"><i class="fas fa-home"></i></div>
                    Home
                </a>
                <a class="nav-link collapsed title cursor-pointer" @click="redirect('profile');" :class="{'itemOn':  this.$route.path == '/profile'}" id="menu_autogestion" data-bs-toggle="collapse" data-bs-target="#collapseDashboards" aria-expanded="false" aria-controls="collapseDashboards">
                    <div class="nav-link-icon"><i class="fas fa-user-edit"></i></div>
                    Profile
                </a>
                <div class="sidenav-menu-heading">Data</div>
                <a class="nav-link collapsed title cursor-pointer" @click="redirect('billing');" :class="{'itemOn': this.$route.path == '/billing'}" id="menu_consultas" data-bs-toggle="collapse" data-bs-target="#collapseFlowsIngreso" aria-expanded="false" aria-controls="collapseFlowsIngreso">
                    <div class="nav-link-icon"><i class="fa-regular fa-credit-card"></i></div>
                    Billing
                </a>
                <a class="nav-link collapsed title cursor-pointer" @click="redirect('table');" :class="{'itemOn': this.$route.path == '/table'}" id="menu_gestiones" data-bs-toggle="collapse" data-bs-target="#collapseFlowsIngreso" aria-expanded="false" aria-controls="collapseFlowsIngreso">
                    <div class="nav-link-icon"><i class="fas fa-folder-plus"></i></div>
                    Table
                </a>
                <div class="sidenav-menu-heading">Action</div>
                <a class="nav-link collapsed title cursor-pointer"  @click="redirect('');" id="menu_salir" data-bs-toggle="collapse" data-bs-target="#collapseDescargas" aria-expanded="false" aria-controls="collapsePages">
                    <div class="nav-link-icon"><i class="fas fa-sign-out-alt"></i></div>
                    Exit
                </a>
            </div>
        </div>
        <!-- Sidenav Footer-->
        <div class="sidenav-footer" style="height:7rem !important">
            <div class="row mt-2 mb-2">
                <div class="sidenav-footer-content">
                    <div class="sidenav-footer-subtitle">User:</div>
                    <div class="sidenav-footer-title">example@webOk.com</div>
                </div>
            </div>
        </div>
    </nav>
</div>
</template>

<script>
import router from '@/router/index.js' // Vue router instance
export default {
    name: 'Sidebar',
    components: {},
    props: {},
    mounted: function () {},
    methods: {
        redirect: function (path) {
            location.href= '/' + path;
        },
    },
    watch: {},
    computed: {}
}
</script>

<style lang="scss">
.title {
    color: black !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

#accordionSidenav {
    overflow-y: hidden !important;
}

.span-menu-pendiente {
    font-size: 12px;
    color: #d1d102
}

.span-menu-aceptado {
    font-size: 12px;
    color: green
}

.itemOn {
    color: #113748 !important;
    letter-spacing: 0.5px;
    transition: 300ms ease;

    &.title {
        color: #113748 !important;
    }

    * {
        color: #113748 !important;
        letter-spacing: 0.5px;
        transition: 300ms ease;
    }
}

.notificacion-mensaje {
    margin-left: 10px;
    background: #e47979;
    padding: 2px 7px 2px 8px;
    border-radius: 12px;
    color: white !important;
    font-size: 13px;
    text-decoration: none !important;
}
</style>
